const isEmailValid = (email) => {
  if (email.trim() === '') return false
  const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regExp.test(email)
}

const getUserOS = () => {
  const {userAgent} = window.navigator
  const {platform} = window.navigator
  const macOSPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = 'Unknown'

  if (macOSPlatforms.indexOf(platform) !== -1) {
    os = 'macOS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }

  return os;
}


module.exports = {
  isEmailValid,
  getUserOS
}