import React from 'react'
import Reaptcha from 'reaptcha'
import classNames from 'classnames'

import Layout from '../components/layout'
import Meta from '../components/meta'
import utils from '../utils/utils'
import siteConfig from '../utils/site-config'

class ContactUsPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isSubmitting: false,
      isEmailValid: true,
      isMessageValid: true,
      isCaptchaValid: true,
      animateError: false
    }
    this.form = React.createRef()
    this.emailInput = React.createRef()
    this.messageTextarea = React.createRef()
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.captcha.getResponse()
      .then((captchaResponse) => {
        const isEmailValid = utils.isEmailValid(this.emailInput.current.value)
        const isMessageValid = this.messageTextarea.current.value.trim() !== ''
        const isCaptchaValid = !!captchaResponse
        if (!isEmailValid || !isMessageValid || !isCaptchaValid) {
          this.setState({
            isEmailValid,
            isMessageValid,
            isCaptchaValid,
            animateError: true
          })
          setTimeout(() => this.setState({animateError: false}), 1000)
        } else {
          this.setState({isSubmitting: true})
          this.form.current.submit()
        }
      })
  }

  onFocus = (e) => {
    const {name} = e.target
    this.setState({
      [`is${name}Valid`]: true
    })
  }

  render () {
    return (
      <>
        <Meta
          title="Contact Us"
        />
        <Layout>
          <div className="typography-page limiter">
            <div className="contact-us">
              <h2 className="ta-center">Contact Us</h2>
              <form
                onSubmit={this.onSubmit}
                ref={this.form}
                action="/api/handle-contact-us-form-request"
                method="post"
                className="form"
              >
                <div className="form-control">
                  <label htmlFor="contact-us-name" className="form-control__label">Name</label>
                  <input
                    name="Name"
                    id="contact-us-name"
                    type="text"
                    className="form-control__element"
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="contact-us-email" className="form-control__label">Email *</label>
                  <input
                    onFocus={this.onFocus}
                    ref={this.emailInput}
                    name="Email"
                    id="contact-us-email"
                    type="text"
                    className={classNames('form-control__element', {
                      'form-control__element--error': !this.state.isEmailValid,
                      'error-shake': !this.state.isEmailValid && this.state.animateError
                    })}
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="contact-us-message" className="form-control__label">Message *</label>
                  <textarea
                    onFocus={this.onFocus}
                    ref={this.messageTextarea}
                    name="Message"
                    id="contact-us-message"
                    className={classNames('form-control__element', {
                      'form-control__element--error': !this.state.isMessageValid,
                      'error-shake': !this.state.isMessageValid && this.state.animateError
                    })}
                  />
                </div>
                <Reaptcha
                  ref={(e) => this.captcha = e}
                  className={classNames('g-recaptcha', {
                    'error-shake': !this.state.isCaptchaValid && this.state.animateError
                  })}
                  sitekey={siteConfig.reCaptchaKey}
                  onVerify={(code) => console.log(code)}
                />
                <button
                  disabled={this.state.isSubmitting}
                  className="btn btn--primary form__submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default ContactUsPage
